.second_page {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.second_container {
  display: flex;
  position: relative;
  justify-content: space-around;
  width: 85%;
  top: 20%;
}

.part {
  white-space: pre-line;
  color: #dddddde2;
  text-align: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 45%;
  min-height: 100%;
  opacity: 0;
  padding: 1rem;
  border-radius: 10px;
  background-color: #002aff10;
  box-shadow: 0 0 10px 0 #000000;
  transition: all 0.5s ease-in-out;
  margin: 0 1rem;
  line-height: 1.5;
}

.part h1,
.part h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

titr {
  font-size: 2rem;
  -webkit-text-stroke-width: .3px;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-fill-color: #000000;
}
.part p,
.part ul {
  font-size: 1rem;
  line-height: 1.5;
}

.visible {
  opacity: 1;
}

.visible.part:nth-child(1) {
  animation: slideFromLeft 1s ease-in-out forwards;
}

.visible.part:nth-child(2) {
  animation: slideFromRight 1s ease-in-out forwards;
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .second_container {
    flex-direction: column;
    align-items: center;
    top: 7%;
    
  }

  .part {
    margin: 0.2rem;
    margin-bottom: 15px;
    max-width: 95%;
    height: 80px;
  }

  .part p,
  .part ul {
    font-size: 0.6rem;
  }
}

@media screen and (max-device-aspect-ratio: 2/3) and (hover: none) {
  /* iOS-specific CSS here; iOS teaches us patience :| */
  .second_page {
    height: 30vh;
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .second_page {
    height: 35rem;
}
}