nav {
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 3px 0;
  z-index: 9999;
  background-color: black;
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  gap: 5px;
  padding: 0;
  padding-top: 20px;
  margin: 0;
}

nav ul li {
  color: #fff;
  padding: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: #a8a8a856 0px 1px 0px 1px;
  text-align: center;
  width: 90px;
  height: 43px;
  border-top-left-radius: 50% 90%;
  border-bottom-right-radius: 50% 100%;
}

.nav-label {
  display: block;
  font-size: 0.6rem;
  text-align: center;
  margin-top: 5px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

nav ul li:hover {
  color: #bc0000;
  background-color: #15ff00;
}

.active {
  background-color: #bc0000;
  zoom: 1.06;
  transform: matrix(1.1, 0, 0, 1.1, 0, 0);
}


#blog-button {
  transform-origin: center bottom;
  border-top-left-radius: 50% 90%;
  border-bottom-right-radius: 50% 100%;
  background-color: rgba(0, 26, 255, 0.134);
  animation: seesaw 4s infinite linear, shadow-move 4s infinite linear;
}

@keyframes seesaw {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes shadow-move {
  0% {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  }
  20% {
    box-shadow: 4px 4px 10px rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  }
  80% {
    box-shadow: -4px -4px 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  }
}



@media (max-width: 768px) {
  nav ul li {
    font-size: 0.9rem;
    padding: 3px;
    width: 70px;
    height: 33px;
  }

  .nav-label {
    font-size: 0.5rem;
  }
}