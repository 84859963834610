/* Title Styling */
.title {
  width: 100%;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  text-align: center;
}

.first-title {
  padding: 30px;
  font-size: 1.5em;
  font-weight: 900;
  color: #FFFAF0;
}

.second-title {
  width: 100%;
  height: 20px;
  color: #FFFAF0;
}

.typed-text-container {
  height: 60px;
}

/* Button Styles */
.resume_button {
  position: fixed;
  display: inline-block;
  padding: 8px 10px;
  color: #fffaf0;
  background-color: #8e0404;
  border: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  font-size: .8rem;
  font-weight: 700;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 0 1px #ffffff;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.resume_button:hover {
  background-color: #12a819;
  color: #fffaf0;
  transform: scale(1.05);
}

.btn-back {
  display: inline-block;
  padding: 8px 10px;
  color: #fffaf0;
  background-color: #ce1515;
  border: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.btn-back:hover {
  background-color: #ffffff;
  color: #000000;
  transform: scale(1.05);
}

/* Box Styles */
.box {
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 100px;
  left: 51.2%;
  transform: translateX(-55%) scale(0.4);
}

.box .leaf {
  position: fixed;
}

/* Leaf Styles */
.leaf {
  right: 120px;
  margin-top: 80px;
  width: 130px;
  height: 130px;
  border-radius: 100% 0px;
  background: linear-gradient(40deg, #ffffff 10%, #f2ff00 40%, #ff0000 100%);
  opacity: 0;
  transform-origin: top right;
}

.leaf.is-visible {
  filter: alpha(opacity=50);
  transform: rotate(90deg);
  opacity: 0.8;
}

/* Keyframe Animations */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  99% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(0deg);
  }
}

@keyframes show-1 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(71deg);
  }
}

@keyframes show-2 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(96deg);
  }
}

@keyframes show-3 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes show-4 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(123deg);
  }
}

@keyframes show-5 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(149deg);
  }
}

@keyframes show-6 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(175deg);
  }
}

@keyframes show-7 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(200deg);
  }
}

@keyframes show-8 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(225deg);
  }
}

@keyframes show-9 {
  0% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes show-10 {
  0% {
    transform: rotate(-98deg);
  }
  100% {
    transform: rotate(-98deg);
  }
}

/* Leaf Visibility and Animation */
.leaf.is-visible:nth-child(1) {
  animation: show-1 2023ms ease-in-out forwards;
  filter: brightness(100);

}

.leaf.is-visible:nth-child(2) {
  animation: show-2 2023ms ease-in-out forwards;
  filter: brightness(100);
}

.leaf.is-visible:nth-child(3) {
  animation: show-3 2023ms ease-in-out forwards;
}

.leaf.is-visible:nth-child(4) {
  animation: show-4 2023ms ease-in-out forwards;
}

.leaf.is-visible:nth-child(5) {
  animation: show-5 2023ms ease-in-out forwards;
}

.leaf.is-visible:nth-child(6) {
  animation: show-6 2023ms ease-in-out forwards;
  
}

.leaf.is-visible:nth-child(7) {
  animation: show-7 2023ms ease-in-out forwards;
  filter: brightness(100);

}

.leaf.is-visible:nth-child(8) {
  animation: show-8 2023ms ease-in-out forwards;
  
}

.leaf.is-visible:nth-child(9) {
  animation: show-9 2023ms ease-in-out forwards;
  width: 90px;
  height: 90px;
  margin-top: 90px;
  left: -10px;
  top: 20px;
  opacity: 0.4;
  background: green;
}

.leaf.is-visible:nth-child(10) {
  animation: show-10 2023ms ease-in-out forwards;
  width: 90px;
  height: 90px;
  margin-top: 90px;
  left: -10px;
  top: 20px;
  opacity: 0.4;
  background: green;
}