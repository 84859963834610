.TirdPage_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  flex-direction: column;
  z-index: 999999;
}

.thirdpage_title_my-projects {
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}

/* Card container with 3D perspective */
.tird_page-card {
  position: relative;
  width: 90%;
  height: 60vh;
  perspective: 1000px; /* Enables 3D effect */
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 12px;
  border-top-left-radius: 50% 90%;
  border-bottom-right-radius: 50% 100%;
}

/* Front and back card faces */
.tird_page-card .card-front,
.tird_page-card .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hides the back when not visible */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  transition: transform 0.6s ease-in-out;
}


.card-front-frame{
  position: relative;
  width: 280px;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-image: url("../../img/cards.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: #00000039 0px 0px 10px;
  box-shadow: #00000039 0px 0px 10px;
}

.card-front-frame:hover{
  box-shadow: #fbff00 0px 0px 10px;
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
  z-index: 999999;

}
/* Front card styling */
.tird_page-card .card-front {
  position: relative;
  transform: rotateY(0deg); /* Show the front */
  color: #000000;
}

/* Back card styling */
.tird_page-card .card-back {
  transform: rotateY(180deg); /* Flips the back face */
}

.card-back{
  background-color: aliceblue;
}
.card-back h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
  letter-spacing: 2px;
}
.card-back a {
  margin-top: 20px;
  background-color: #0059ff;
  border: #00000039 2px solid;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 5px;
  text-decoration: none;
}
/* Flip card */
.tird_page-card.flipped .card-front {
  transform: rotateY(180deg); /* Hide the front */
}

.tird_page-card.flipped .card-back {
  transform: rotateY(360deg); /* Show the back */
}

.thirdpage_front_card-label {
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #000000;
}

.flip_description {
  position: relative;
  direction: ltr;
  text-align: left;
  font-size: 0.9rem;
  padding: 8px 18px 18px 18px;
  box-shadow: #00000039 0px 0px 10px;
  border-radius: 12px;
  top: 8px;
}
.flip_description h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #ff0000;
  margin-top: 5px;
}
.flip_description p {
  color: #000000;
}
/* Navigation arrows */
.arrow_third_page {
  position: absolute;
  font-size: 2.8rem;
  color: #000;
  cursor: pointer;
  z-index: 1000;
  user-select: none;
}

.arrow_third_page-left,
.arrow_third_page-right {
  background-color: #eafaff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.arrow_third_page-left {
  left: 10%;
}

.arrow_third_page-right {
  right: 10%;
}

/* Dot navigation */
.dots-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10%;
  width: 100%;
}

.dot {
  height: 5px;
  width: 20px;
  margin: 0 8px;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 1px 0px white;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: white;
}

.card-image {
  position: relative;
  height: 150px;
  width: 150px;
  object-fit: cover;
  z-index: 99998;
}

@media screen and (max-width: 1199px) {
  .card-image {
    height: 100px;
    width: 100px;
  }
  .flip_description {
    width: 60%;
    font-size: 0.6rem;
  }
  .card-back a {
    margin-top: 15px;
    background-color: #0059ff;
    border: #00000039 2px solid;
    color: #ffffff;
    padding: 2px 10px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 0.8rem;
    z-index: 999999;
    cursor: pointer;
  }
  .arrow_third_page-left,
  .arrow_third_page-right {
    font-size: 2rem;
  }
}
