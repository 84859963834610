.scroller_carousel {
  width: 50%;
  height: 100px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  bottom: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:2;
}

.scroller__inner_carousel {
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  text-align: center;
  position: relative;
}

.scroller__inner_carousel-background {
  border-radius: 10px;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

.scroller__inner_carousel-background img {
  width: 30px;
}

.scroller__inner_carousel-background img:hover {
  width: 70px;
  z-index: 1;
  transition: 0.5s;
  border: rgb(68, 0, 255) 2px dotted;
  border-radius: 50%;
}

.scroller_carousel[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
}

.scroller_carousel[data-animated="true"] .scroller__inner_carousel {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller_carousel[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller_carousel[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller_carousel[data-speed="fast"] {
  --_animation-duration: 10s;
}

.scroller_carousel[data-speed="slow"] {
  --_animation-duration: 50s;
}

@keyframes scroll {
  to {
    transform: translateX(calc(-50%));
  }
}

.tag-list {
  position: relative;
  bottom: 35px;
  color: white;
}

.tag-list li {
  margin: 1rem;
  rotate: 0deg;
  font-size: .7rem;
}

.scroller_carousel:hover .scroller__inner_carousel {
  animation-play-state: paused;
}

@media screen and (max-width: 768px) {
  .scroller_carousel {
    width: 100%;
  }
  
}